import React, { useCallback, useRef } from 'react'
//import 'antd/dist/antd.css';
import { Table, Input, Button, Space, PageHeader, Popconfirm, Breadcrumb, Modal, Form, Row, Col, } from 'antd';
import Highlighter from 'react-highlight-words';
import Resizer from 'react-image-file-resizer';
import { DeleteOutlined, EditOutlined, EyeFilled, PlusCircleFilled, SearchOutlined } from '@ant-design/icons';
import { Content } from 'antd/lib/layout/layout';
import { createDoctor, updateDoctor, convertById, getDoctorById, getAllDoctors, } from "../../../actions/tvadd";
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { useState } from 'react';
import { useEffect } from 'react';
import { renderInput, renderSelect, } from '../../../component/form/form';
import { toPng, toJpeg, toBlob, toPixelData, toSvg } from 'html-to-image';
import download from 'downloadjs';
const Search = Input.Search;

const DoctorList = ({ getAllDoctors, getDoctorById, createDoctor, updateDoctor, convertById }) => {
  const [ID, setID] = useState('')
  const [data, setData] = useState(null)
  const [viewData, setViewData] = useState(null)
  const [searchText, setSearchText] = useState('')
  const [searchedColumn, setSearchedColumn] = useState('')
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isViewModalOpen, setIsViewModalOpen] = useState(false);
  const [image, setImage] = useState('');
  const [form] = Form.useForm();
  const [cform] = Form.useForm();
  const [eform] = Form.useForm();
  const ref = useRef(null)

  const getData = async () => {
    const res = await getAllDoctors()
 
    setData(res);
  }
  useEffect(() => {
    getData()
  }, [])

  const showModal = () => {
    setIsCreateModalOpen(true);

  };

  const handleOk = () => {
    setIsCreateModalOpen(false);
  };

  const handleCancel = () => {
    setIsCreateModalOpen(false);
  };

  const handleView = async (item) => {
    setID(item?.uuid)
    const res = await getDoctorById(item?.uuid)
    setViewData(res)
      setIsViewModalOpen(true);
  }
  const handleEdit = async (item) => {
    setID(item?.uuid)
    const res = await getDoctorById(item?.uuid)
    eform.setFieldsValue({
        name: res?.name,
        designation: res?.designation,
        specialist: res?.specialist,
        mmbs_id: res?.mmbs_id,
        appionment_number: res?.appionment_number,
        previous_work_place: res?.previous_work_place,
        current_work_place: res?.current_work_place,
        patient_visit_time: res?.patient_visit_time,
        hospital_name: res?.hospital_name,
        experiance: res?.experiance,
    })
    setIsEditModalOpen(true);
  }
  
  const getColumnSearchProps = dataIndex => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Search
          // ref={node => {
          //   searchInput = node;
          // }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}
          >
            Search
          </Button>
          <Button onClick={() => handleReset(clearFilters)} size="small" style={{ width: 90 }}>
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              confirm({ closeDropdown: false });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button>
        </Space>
      </div>
    ),
    filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
        : '',
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        // setTimeout(() => searchInput.select(), 100);
      }
    },
    render: text =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = clearFilters => {
    clearFilters();
      setSearchText('');
  };

  const createData = (id, name, designation, specialist, action) => {
    return {id, name, designation, specialist, action};
  };

  const columns = [
    {
      title: 'SL',
      dataIndex: 'id',
      key: 'id',
      width: '10%',
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      width: '20%',
      ...getColumnSearchProps('name'),
    },
    {
      title: 'Designation',
      dataIndex: 'designation',
      key: 'designation',
      width: '20%',
    },
    {
      title: 'Specialist',
      dataIndex: 'specialist',
      key: 'specialist',
      width: '20%',
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      width: '20%',
    },
  ];

  const rows = [
    data
      ?  data?.map((item, key) => (
        createData(
          key + 1, 
          item?.name, 
          item?.designation,
          item?.specialist,
          <>
            <Button size="small" type="primary" icon={<EyeFilled  />} onClick={() => handleView(item)}>View</Button> &nbsp;
            <Button size="small" type="primary" icon={<EditOutlined  />} onClick={() => handleEdit(item)}>Edit</Button> &nbsp;
          </>
          )
      ))
        :null
    ];
;


const handleImageChange = (e) => {
    let nam = e.target.name;
    let val = e.target.value;
        var type= e.target.files[0].type;
        if (e.target.files && e.target.files[0]) {
          var fsize = e.target.files[0].size/1024;
            if( type == 'image/jpg' || type == 'image/jpeg' || type == 'image/JPG' || type == 'image/JPEG' || type == 'image/PNG' || type == 'image/png' ){
            
                try {
                    Resizer.imageFileResizer(
                    e.target.files[0],
                    600,
                    400,
                    'PNG',
                    100,
                    0,
                    uri => {
                        setImage(uri) 
                    },
                    'blob',
                    600,
                    400,
                    );
                    }   
                    catch(err) {
                    }
            }
            else{
    
                alert('Invalid image format');
                return true;
            }
        }
    }

    const onFinish = async (values) => {
        const res = await createDoctor(values, image);
        if(res ===  201){
        getData();
        setID('');
        setIsCreateModalOpen(false);
        cform.resetFields();
        }
    };

  const onFinishEdit = async (values) => {
    const res = await updateDoctor(values, ID);
    if(res ===  201){
      getData();
      setID('');
      setIsEditModalOpen(false);
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };

  const tailLayout = {
    wrapperCol: { offset: 4, span: 16 },
  };

  const status = [
    {value: 'major', label: 'Major'},
    {value: 'minor', label: 'Minor'},
  ]
  const virefy = useCallback( () => {
        if (ref.current === null) {
          return;
        }
    
        toPng(ref.current, { cacheBust: true, })
          .then((dataUrl) => {
            
            download(dataUrl, 'my-node.png');
          })
          .catch((err) => {
            console.log(err)
          })
    }, [ref])
  const handleDownload = async () => {
  const res  = await convertById(ID)
  if(res === 201 ){
    virefy();
  }
}
  return ( 
    <Content style={{ margin: '0 16px' }}>

      <Breadcrumb style={{padding:"10px 0px"}}>
        <Breadcrumb.Item><Link to="/dashboard">Home</Link></Breadcrumb.Item>
        <Breadcrumb.Item>Doctors</Breadcrumb.Item>
      </Breadcrumb>

      <PageHeader
        ghost={false}
        onBack={() => window.history.back()}
        title="Dotors"
        subTitle="App Management"
        extra={[
          <Button key="1"  type="primary" icon={<PlusCircleFilled />} onClick = { showModal}>Create New
          </Button>,
        ]}
      >
      </PageHeader>

      <Table columns={columns} pagination={true} dataSource={rows[0]} scroll={{ x: 1000 }} sticky/>
      <br />
      
      <Modal 
        title="Add a new Doctors" 
        open={isCreateModalOpen} 
        onOk={handleOk} 
        onCancel={handleCancel} 
        width={800}
        footer={null}
      >
        <Form
          {...layout}
          name="basic"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          form={cform}
        >
          
          
          {renderInput('name', 'Name', [
              { required: true, message: 'This field is required!' },
            ], 'small')
          }
          
          {renderInput('designation', 'Designation', [
              { required: true, message: 'This field is required!' },
            ], 'small')
          }
          
          {renderInput('specialist', 'Specialist', [
              { required: true, message: 'This field is required!' },
            ], 'small')
          }
          
          {renderInput('mmbs_id', 'BMDC Number', [
              { required: false, message: 'This field is required!' },
            ], 'small')
          }
          
          {renderInput('appionment_number', 'Appionment Number', [
              { required: false, message: 'This field is required!' },
            ], 'small')
          }
          
          {renderInput('previous_work_place', 'Previous Work Place', [
              { required: false, message: 'This field is required!' },
            ], 'small')
          }
          
          {renderInput('current_work_place', 'Current Work Place ', [
              { required: false, message: 'This field is required!' },
            ], 'small')
          }
          
          {renderInput('patient_visit_time', 'Patient Visit Time', [
              { required: false, message: 'This field is required!' },
            ], 'small')
          }
          
          {renderInput('hospital_name', 'Hospital Name', [
              { required: false, message: 'This field is required!' },
            ], 'small')
          }
          
          {renderInput('experiance', 'Experiance', [
              { required: false, message: 'This field is required!' },
            ], 'small')
          }

          <Form.Item
              label={"Upload  File"}
              name="img"
              rules={[
              {
                  required: true,
                  message: "This field is required",
              },
              ]}
          >
              <Input type="file" name="img" onChange={(e)=>handleImageChange(e)}/>
          </Form.Item>
           
          <Form.Item {...tailLayout}> 
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
                
        </Form>
      </Modal>
      
      <Modal 
        title="Edit Doctors" 
        open={isEditModalOpen} 
        onOk={() => setIsEditModalOpen(false)} 
        onCancel={() => setIsEditModalOpen(false)} 
        width={800}
        footer={null}
      >
        <Form
          {...layout}
          name="basic"
          initialValues={{ remember: true }}
          onFinish={onFinishEdit}
          onFinishFailed={onFinishFailed}
          form={eform}
        >
          
          
          {renderInput('name', 'Name', [
              { required: true, message: 'This field is required!' },
            ], 'small')
          }
          
          {renderInput('designation', 'Designation', [
              { required: true, message: 'This field is required!' },
            ], 'small')
          }
          
          {renderInput('specialist', 'Specialist', [
              { required: true, message: 'This field is required!' },
            ], 'small')
          }
          
          {renderInput('mmbs_id', 'BMDC Number', [
              { required: false, message: 'This field is required!' },
            ], 'small')
          }
          
          {renderInput('appionment_number', 'Appionment Number', [
              { required: false, message: 'This field is required!' },
            ], 'small')
          }
          
          {renderInput('previous_work_place', 'Previous Work Place', [
              { required: false, message: 'This field is required!' },
            ], 'small')
          }
          
          {renderInput('current_work_place', 'Current Work Place ', [
              { required: false, message: 'This field is required!' },
            ], 'small')
          }
          
          {renderInput('patient_visit_time', 'Patient Visit Time', [
              { required: false, message: 'This field is required!' },
            ], 'small')
          }
          
          {renderInput('hospital_name', 'Hospital Name', [
              { required: false, message: 'This field is required!' },
            ], 'small')
          }
          
          {renderInput('experiance', 'Experiance', [
              { required: false, message: 'This field is required!' },
            ], 'small')
          }
           
          <Form.Item {...tailLayout}> 
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
                
        </Form>
      </Modal>

      <Modal 
        title="Doctor Details" 
        open={isViewModalOpen} 
        onOk={() => setIsViewModalOpen(false)} 
        onCancel={() => setIsViewModalOpen(false)} 
        width={800}
        footer={null}
        form={form}
      >
      <Row gutter={16} ref={ref} style={{ backgroundColor: '#FFF' }}>
          <Col xs={7}
              style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
          >
              <img
                  style={{ borderRadius: '50%', alignSelf: 'center', width: '200px' }}
                  src={`https://api.aurora.hidayahsmart.solutions/static/${viewData?.uuid}_DOC.png`} alt="image"
              />
          </Col>
          <Col xs={17}>
              <h2 style={{ fontWeight: '800', fontSize: '32px', margin: '0px', padding: '0px' }}>{viewData?.name}</h2>
              <h2 style={{ margin: '0px', padding: '0px' }}>{viewData?.designation}</h2>
              <h2><br /></h2>
              <h2 style={{ margin: '0px', padding: '0px' }}>{viewData?.specialist}</h2>
              {/* <h2 style={{ margin: '0px', padding: '0px' }}>{viewData?.hospital_name}</h2> */}
              <h2 style={{ margin: '0px', padding: '0px' }}>{viewData?.mmbs_id}</h2>
              <p></p>
              <h2 style={{ margin: '0px', padding: '0px' }}>{viewData?.patient_visit_time}</h2>
              <p></p>
              <h2 style={{ margin: '0px', padding: '0px' }}>{viewData?.hospital}</h2>
          </Col>
      </Row>
             <Button onClick={handleDownload}>Download</Button>
      </Modal>
      
    </Content>
   );
}
 
export default connect(null, { getAllDoctors, getDoctorById, createDoctor, updateDoctor, convertById })(DoctorList);